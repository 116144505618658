<template>
    <div class="w-full flex flex-wrap h-full ">

		<div class="w-2/5">
            <div class="object-cover w-full min-h-full hidden md:block bg-red-600">
			</div>
        </div>

        <div class="w-full md:w-3/5 flex flex-col">
			<div class="flex flex-col justify-center md:justify-start my-auto pt-8 md:pt-0 px-8 md:px-44 lg:px-44">
				<img src="@/assets/images/shield.svg" class="w-36 mx-auto md:mt-20 mt-14" />

				<div>
					<h2 class="mt-6 text-left text-3xl font-extrabold text-gray-900">
						E-learning Gamifikasi
					</h2>

					<p class="mt-2 text-left text-sm text-gray-600">
						Silahkan masukan informasi akun anda untuk melanjutkan.
					</p>

					<!-- <p class="mt-2 text-left text-sm text-gray-600">
						Atau
						<router-link :to="{ name  : 'register'}" class="font-medium text-red-600 hover:text-red-500">
							Daftarkan akun anda
						</router-link>
					</p> -->
				</div>
				<form class="mt-8 mb-10 space-y-7" @submit="submitLogin()">
					<custom-input id="email" type="email" label="Email" v-model="mainForm.email" :errorMessage="errorForm.email"/>
					<div class="relative">
						<div class="cursor-pointer absolute right-3 top-2 z-10" @click="isShowPass = !isShowPass">
							<svg v-if="!isShowPass" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
								<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
								<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
							</svg>
							<svg v-if="isShowPass" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
								<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
							</svg>
						</div>
						<custom-input id="password" :type="isShowPass ? 'text' : 'password'" label="Password" v-model="mainForm.password" :errorMessage="errorForm.password"/>
					</div>

					<!-- <div class="flex items-center justify-end">
						<div class="text-sm">
							<router-link :to="{name: 'resetPassword'}" class="font-medium text-red-600 hover:text-red-500">
								Lupa password?
							</router-link>
						</div>
					</div> -->

					<div>
						<button type="submit"
						@click="submitLogin()" 
						:disabled="isLoading"
						class="group relative w-full flex justify-center py-3 px-4 border border-transparent text-sm font-lg rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
							<span class="absolute left-0 inset-y-0 flex items-center pl-3">
								<svg class="h-5 w-5 text-red-500 group-hover:text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
									<path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" />
								</svg>
							</span>
							Login
						</button>
					</div>



				</form>
				<loading-modal :isShow="isLoading" />
			</div>
		</div>

	</div>
</template>
<script>
	import CustomInput from '../components/CustomInput.vue';
	import LoadingModal from '../components/LoadingModal.vue';
	import {showSuccessNotif, showErrorNotif, extractValidationError} from '../helpers/Tools';

    export default {
		name: 'Login',
		components : {
			CustomInput, LoadingModal
		},
		data () {
            return {
				isLoading : false,
				isShowPass : false,
                mainForm: {
                    email    : null,
                    password : null,
                    fcm_token: null
                },
                errorForm : {
                    email   : null,
                    password: null,
                },
            }
        },
        mounted() {
            this.initFcmToken();
		},
		methods : {
			async submitLogin () {
                this.isLoading = true;

                try {
                    this.errorForm = {
                        email   : null,
                        password: null,
					};

					let res            = await this.$store.dispatch("postLogin", this.mainForm);
					let result         = res.data;
					    this.isLoading = false;

					if(result.status == 'success') {
						showSuccessNotif(this, result.message);

						let userData = result.data;
						this.$store.dispatch('actionLoginUser', userData)
						this.$router.replace({
							path: '/'
						});

					} else {
                        this.errorForm = extractValidationError(this.errorForm, result.data);
					}

                } catch (error) {
                    this.isLoading = false;
                    showErrorNotif(this);
                }
            },

			initFcmToken() {
                let token = new URL(location.href).searchParams.get('token')
				if(token != null) {
					this.mainForm.fcm_token = token;
				}
            }
        }
    }
</script>
